<script>
    export default {
        name: 'Link',

        props: {
            type: String,
            target: String,
            data: Object
        },

        render(createElement) {
            if (this.data.type === 'page' || this.data.type === 'post') {
                return createElement('router-link', {
                    attrs: {
                        to: this.data.page ? `/page/${this.data.page.post_name}` : `/post/${this.data.post.post_name}`
                    }
                }, this.$slots.default)
            }

            return createElement('a', {
                attrs: {
                    href: this.data.link
                }
            }, this.$slots.default)
        }
    }
</script>