<template>
    <div class="c-widget--bg">
        <div class="o-container c-infobox" :class="{ 'c-infobox--has-img' : data.image, 'c-infobox--has-links' : data.links }">
            <h2 class="c-widget__title c-infobox__title">{{ data.title }}</h2>
            <div v-if="data.image" class="c-infobox__img"
                 :style="{ backgroundImage: `url(${ data.image.sizes.large.src[0] })` }"></div>
            <div class="c-infobox__content">
                <div class="c-txt c-txt--italic c-infobox__desc" v-html="data.description"></div>
                <div class="c-infobox__links">
                    <dynamic-link v-for="(link, i) in data.links" :data="link" class="c-link c-link--has-icon c-link--arrow c-txt--upper"
                       :key="i">
                        {{ link.text }}
                        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 15" v-bind:svg-inline="''" v-bind:class="'c-icon c-icon--arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 1l6 6.5L1 14"/></svg>
                    </dynamic-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicLink from '@/components/partials/Link'

    export default {
        name: 'PostInfobox',
        props: {
            data: Object
        },

        components: {
            DynamicLink
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_infobox.scss"></style>